<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Категории</div>
                <div class="page__desc">Список категорий</div>
            </div>
            <div class="page__actions">
                <router-link
                        :to="{ name: 'CatalogCategoryCreate', params: { parent_id: this.$route.params.id } }">
                    <button type="button" class="btn btn-success">Добавить</button>
                </router-link>
            </div>
        </div>

        <div class="page__content">
            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th>Название</th>
                        <th></th>
                        <th>Действие</th>
                    </tr>
                    <draggable v-model="categories" draggable="tr" tag="tbody">
                        <tr v-for="(category, index) in categories"
                            v-bind:key="category.id"
                            style="cursor: move;">
                            <td>{{ category.title }}</td>
                            <td>
                                <router-link
                                        :to="{ name: 'CatalogSubcategories', params: { id: category.id } }"
                                        v-if="category.parent_id === null">
                                    <button type="button" class="btn btn-sm btn-outline-info">Подкатегории</button>
                                </router-link>
                                <router-link
                                        :to="{ name: 'CatalogCategoriesProducts', params: { id: category.id } }"
                                        v-else>
                                    <button type="button" class="btn btn-sm btn-outline-info">Товары</button>
                                </router-link>
                            </td>
                            <td>
                                <router-link
                                        :to="{ name: 'CatalogCategoryUpdate', params: {id: category.id } }"
                                        tag="input"
                                        type="button"
                                        class="btn btn-sm btn-warning"
                                        value="Изменить"/>
                                <input
                                        @click="deleteCategory(index)"
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        value="Удалить">
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'
    import draggable from 'vuedraggable'
    import * as types from '@/store/mutation-types'

    export default {
        name: 'CatalogCategories',
        components: {
            draggable,
        },
        computed: {
            categories: {
                get() {
                    return this.$store.state.catalog.categories
                },
                set(value) {
                    let data = [];
                    for (let i=0; i<value.length; i++) {
                        data.push({id: value[i].id, sort: i});
                    }
                    this.sortCategories(data);

                    this.$store.commit(types.FILL_CATALOG_CATEGORIES, value)
                }
            }
        },
        methods: {
            ...mapActions([
                'catalogCategories',
                'catalogCategoriesSort',
                'catalogCategoryDelete'
            ]),
            async getCategories() {
                await this.catalogCategories({
                    id: this.$route.params.id === undefined ? null : this.$route.params.id,
                })
            },
            async sortCategories(data) {
                await this.catalogCategoriesSort({
                    data: data,
                })
            },
            async deleteCategory(index) {
                if (confirm('Вы уверены, что хотите удалить?')) {
                    let category = this.categories.splice(index, 1)[0];

                    await this.catalogCategoryDelete({
                        id: category.id
                    })
                }
            }
        },
        created() {
            this.getCategories();
        },
        watch: {
            $route() {
                this.getCategories();
            }
        },
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>